// import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../../Components/Buttons/ButtonComponent";

const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";
const blog_image = `${digitalOceanURL}/SingleBlogImg/blog_img_bg.png`;
const blog_small_image = `${digitalOceanURL}/SingleBlogImg/blog_small_img.png`;
const SingleBlogHeroSection = () => {
  return (
    <div className="relative blog_bg_img background_color">
      {/* ---------------------------show this for lg and md device------------------------------------- */}
      <div className="lg:relative lg:top-12 flex flex-col lg:mx-12 box_blog_space">
        <div className="flex flex-col lg:relative lg:right-10 leading-tight z-[2] blog-box ">
          <div className="p-2 mt-4 lg:ml-16">
            <div className="text-wrap mt-14 lg:mt-0 md:mt-20 bold-text font-[poppins] text-center">
              <h1 className="text-[32px] md:text-[46px] lg:text-[46px]">
                All-in-One Onboarding and Verification
              </h1>
            </div>
            <div className="text-wrap px-2 lg:px-0 md:px-0 font-[poppins] mt-4 md:mt-6 lg:mt-6 text-center paragraph blog_para">
              <p>
                Streamline hiring with secure, blockchain-based background checks
                <br className="hidden md:block lg:block" />
                Trust Hirestar.io for verified and transparent employee screening
              </p>
            </div>
          </div>

          {/* --------------------------buttons-------------------- */}
          <div className="flex justify-center mt-6">
            <ButtonComponent arialLabel={"All-in-One Onboarding and Verification"} />
          </div>
        </div>

        <div className="large-image relative mt-8 lg:top-4 ">
          {/* Home Image */}
          <img
            className="home-image w-full object-cover lg:h-auto"
            alt="hero img for single blog page"
            title="hero img  blog page"
            src={blog_image}
            loading="lazy"
          />
        </div>

        {/* -------------------------show this for small device-------------------------------------- */}
        <div className="relative w-full max-w-sm mx-auto small-image mt-8 lg:hidden">
          <div className="overflow-hidden rounded-lg">
            <div className="flex transition-transform duration-300 ease-in-out mb-20">
              <img
                src={blog_small_image}
                alt="small img for single blog page"
                title="small img  blog page"
                className="w-full h-auto flex-shrink-0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default SingleBlogHeroSection;
