import { useState, useEffect } from "react";
import LazyImage from "../../../LazyImg/LazyImage";
import img1 from '../../../assets/img1.svg'
import img2 from '../../../assets/img2.svg'
import img3 from '../../../assets/img3.png'
import img4 from '../../../assets/img4.png'

const digitalOceanURL =
    "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const images = [
    img1,
    img2,
    img3,
    img4
];
const HomePageCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    // console.log("homepage hero");
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000);
        return () => clearInterval(timer);
    }, []);

    return (
        <div className="relative w-full max-w-sm mx-auto small-image">
            <div className="overflow-hidden rounded-lg">
                <div
                    className="flex transition-transform duration-300 ease-in-out"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {images.map((src, index) => (
                        <img
                            key={index}
                            src={src}
                            alt={`Slide ${index + 1} showing hiring benefits`}
                            className="w-full h-auto flex-shrink-0"
                            title={`Hirestar-carousel-Image-${index + 1}`}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default HomePageCarousel