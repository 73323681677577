import "./App.css";
import React, { Suspense, lazy } from 'react';
import "react-toastify/dist/ReactToastify.css";
import AllRoutes from "./Pages/AllRoutes/AllRoutes.jsx";
import Navbar from "./Components/Navbar/Navbar.jsx";
import LazyComponent from "./LazyComponent/LazyComponent.jsx";
import { useLocation } from "react-router-dom";
import LazyLoad from 'react-lazy-load';

// import Footer from "./Components/Footer/Footer.jsx";
const Footer = lazy(() => import("./Components/Footer/Footer.jsx"));
// const Navbar = lazy(() => import("./Components/Navbar/Navbar.jsx"));

function App() {
  
  return (
    <div>
      <AllRoutes />
      <Navbar />
      {/* <LazyLoad offset={0} once={true}>
      </LazyLoad> */}
      <Footer />
      {/* <Suspense fallback={<div>Loading...</div>}>
        <LazyComponent>
          <Footer />
        </LazyComponent>
      </Suspense> */}
    </div>
  );
}

export default App;



