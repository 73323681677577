import LazyImage from "../../LazyImg/LazyImage";
const digitalOceanURL = "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";
const Hr_Img_1 = `${digitalOceanURL}/Section_Icons/Hr1.svg`;
const Hr_Img_2 = `${digitalOceanURL}/Section_Icons/Hr2.svg`;
const Hr_Img_3 = `${digitalOceanURL}/Section_Icons/Hr3.svg`;
const Hr_Img_4 = `${digitalOceanURL}/Section_Icons/Hr4.svg`;
const Hr_Img_5 = `${digitalOceanURL}/Section_Icons/Hr5.svg`;
const Hr_Img_6 = `${digitalOceanURL}/Section_Icons/Hr6.svg`;
const Hr_Img_7 = `${digitalOceanURL}/Section_Icons/Hr7.svg`;
const Hr_Img_8 = `${digitalOceanURL}/Section_Icons/Hr8.svg`;
const Hr_Img_9 = `${digitalOceanURL}/Section_Icons/Hr9.svg`;

// Offer Images
const Offer_Img_1 = `${digitalOceanURL}/Section_Icons/Offer1.svg`;
const Offer_Img_2 = `${digitalOceanURL}/Section_Icons/Offer2.svg`;
const Offer_Img_3 = `${digitalOceanURL}/Section_Icons/Offer3.svg`;
const Offer_Img_4 = `${digitalOceanURL}/Section_Icons/Offer4.svg`;
const Offer_Img_5 = `${digitalOceanURL}/Section_Icons/Offer5.svg`;

// Self Images
const Self_Img_1 = `${digitalOceanURL}/Section_Icons/Self1.svg`;
const Self_Img_2 = `${digitalOceanURL}/Section_Icons/Self2.svg`;
const Self_Img_3 = `${digitalOceanURL}/Section_Icons/Self3.svg`;
const Self_Img_4 = `${digitalOceanURL}/Section_Icons/Self4.svg`;

// Gr Images
const Gr_Img_1 = `${digitalOceanURL}/Section_Icons/Gr1.svg`;
const Gr_Img_2 = `${digitalOceanURL}/Section_Icons/Gr2.svg`;
const Gr_Img_3 = `${digitalOceanURL}/Section_Icons/Gr3.svg`;
const Gr_Img_4 = `${digitalOceanURL}/Section_Icons/Gr4.svg`;
const Gr_Img_5 = `${digitalOceanURL}/Section_Icons/Gr5.svg`;



export default function PortalSection3({ heading, Paragraph, section }) {
  // Card data for HR Portal
  const hrPortalCards = [
    { id: 1, icon: Hr_Img_1, title: 'Real-time Progress Monitoring', description: 'Track verification requests in real-time, ensuring an efficient workflow.' },
    { id: 2, icon: Hr_Img_2, title: 'Instant Record Retrieval', description: 'Simplify your data retrieval with fast, name-based verification.' },
    { id: 3, icon: Hr_Img_3, title: 'Pre-Joining Background Checks', description: 'Conduct thorough checks before onboarding to ensure robust hiring decisions.' },
    { id: 4, icon: Hr_Img_4, title: 'User Experience', description: 'A smooth and intuitive interface tailored for HR managers.' },
    { id: 5, icon: Hr_Img_5, title: 'Integrated Letter of Intent', description: 'Send personalized letters tied to background checks during onboarding.' },
    { id: 6, icon: Hr_Img_6, title: 'Uncompromised Security', description: 'Data security with blockchain technology for confidentiality.' },
    { id: 7, icon: Hr_Img_7, title: 'Downloadable Reports in PDF', description: 'Generate and share reports easily in PDF format.' },
    { id: 8, icon: Hr_Img_8, title: 'Insightful Analytics', description: 'Monitor verification requests with detailed visual analytics.' },
    { id: 9, icon: Hr_Img_9, title: 'Efficient Hiring Solutions', description: 'Enhance the hiring process with seamless background verification.' },
  ];

  // Card data for Self Verification
  const selfVerificationCards = [
    { id: 1, icon: Self_Img_1, title: 'Intuitive User Interface', description: 'Designed for simplicity and ease of use, the portal’s interface ensures a smooth verification process, even for those with limited tech skills.' },
    { id: 2, icon: Self_Img_2, title: 'Multi-Language Support', description: 'Break the language barrier—our platform supports multiple languages, making it accessible to users across the globe, ensuring a seamless experience regardless of linguistic preferences.' },
    { id: 3, icon: Self_Img_3, title: 'Comprehensive Document Verification', description: 'Verify key documents such as Aadhar card, PAN card, and driving license effortlessly, all within a matter of moments.' },
    { id: 4, icon: Self_Img_4, title: 'Face and Identity Checks', description: 'The portal integrates facial recognition to confirm identities, ensuring a higher level of security and trust.' },
  ];

  // Card data for GR+ Portal
  const grPlusPortalCards = [
    { id: 1, icon: Gr_Img_1, title: 'Real-Time Progress Monitoring:', description: 'Observe the progression of background verification requests in real time. GR+ offers HR professionals the capability to oversee each step, ensuring a streamlined, efficient workflow.' },
    { id: 2, icon: Gr_Img_2, title: 'Simplified Grievance Reporting:', description: 'GR+ provides a straightforward approach for submitting and tracking grievances. This allows HR teams to quickly address any potential red flags, ensuring adherence to organizational standards and legal requirements.' },
    { id: 3, icon: Gr_Img_3, title: 'User-Centric Interface:', description: 'Specifically crafted for HR managers and administrators, the interface offers a visually pleasing, intuitive experience, enhancing operational productivity and reducing the need for extensive training.' },
    { id: 4, icon: Gr_Img_4, title: 'Enhanced Candidate Screening:', description: 'With GR+, organizations can effortlessly screen candidates for flagged issues, thus enabling a more informed, risk-mitigated hiring process.' },
    { id: 5, icon: Gr_Img_5, title: 'Transparency and Accountability:', description: 'The platform cultivates a culture of openness and accountability by enabling clear communication channels, which aid in resolving conflicts efficiently while maintaining a positive work environment.' },

  ];

  // Card data for Offer Portal
  const offerPortalCards = [
    { id: 1, icon: Offer_Img_1, title: 'Effortless Offer Monitoring ', description: ' Say farewell to cluttered inboxes and lost messages. The portal centralizes every offer, enabling meticulous tracking that ensures no essential detail is overlooked.' },
    { id: 2, icon: Offer_Img_2, title: 'Streamlined Issue Resolution', description: 'Navigate through hiring conundrums with ease. From addressing moonlighting and offer shopping to mitigating late joins, the portal pre-empts and resolves these challenges, ensuring a smooth, seamless transition from offer acceptance to onboarding.' },
    { id: 3, icon: Offer_Img_3, title: 'Customized Employee Interface', description: 'Craft a personalized experience for your new hires. The dedicated employee portal simplifies the onboarding journey, making it easy for new team members to navigate their induction with confidence and ease.' },
    { id: 4, icon: Offer_Img_4, title: 'Integrated Letter of Intent ', description: 'Foster an initial connection even before the first day. The Letter of Intent feature allows you to deliver a welcoming note that intertwines seamlessly with background verification, launching a holistic onboarding process that sets the right tone from the outset.' },
    { id: 5, icon: Offer_Img_5, title: 'Widespread HR Approval ', description: ' Witness why HR teams cherish the Offer Experience Portal. Its versatility and broad range of customizable options provide an adaptable, user-centric solution, addressing every possible recruitment need. With the portal, elevate your hiring standards and join a community of satisfied professionals.' },

  ];

  // Choose which card set to display based on the section prop
  let cards;
  switch (section) {
    case 'HR_Portal':
      cards = hrPortalCards;
      break;
    case 'Self_Verification':
      cards = selfVerificationCards;
      break;
    case 'GR_Portal':
      cards = grPlusPortalCards;
      break;
    case 'Offer_Portal':
      cards = offerPortalCards;
      break;
    default:
      cards = [];
  }

  return (
    <div className='bg-[#ECF9FF] lg:mt-60'>
      <div className="mx-auto px-4 py-12 lg:mx-12 mt-12">
        <div className="text-center mb-24 mt-16">
          {/* <h2 className="IT_Headings" aria-label={`Key Features Of ${heading}`}>
            Key Features Of <span className='text-[#1783C4]'>{heading}</span> 
          </h2> */}
          {/* updated heading */}
          <h2 className="IT_Headings inline" aria-label={`Key Features Of`}>
            Key Features Of {` `}
          </h2>
          <p className="IT_Headings inline text-[#1783C4]" aria-label={`Key Features Of ${heading}`}>
            {heading}
          </p>
          {/* ------------------- */}
          <p className='text-center mx-2 lg:mx-24'>{Paragraph}</p>
        </div>

        {/* Grid Layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 relative">
          {cards.map((card, index) => (
            <div key={card.id} className="relative p-6 flex flex-col items-start space-y-4 ">

              {/* Top border */}
              {index > 3 && (index + 1) % 3 === 0 && (
                <div className="w-[80%] mx-auto h-[1px] bg-gradient-to-r from-transparent via-[#A3E4FF] to-transparent hidden lg:block"></div>
              )}

              {/* Left border */}
              <div className="absolute left-0 top-0 h-[80%] w-[1px] my-auto bg-gradient-to-b from-transparent via-[#A3E4FF] to-transparent"></div>

              {/* Right border */}
              {(index + 1) % 3 === 0 && (
                <div className="absolute right-0 top-0 h-[80%] w-[1px] bg-gradient-to-b from-transparent via-[#A3E4FF] to-transparent hidden lg:block"></div>
              )}

              {/* Icon at the top */}
              <div className="h-16 w-16 bg-white flex items-center justify-center side_box">
                {/* <img loading="lazy"
                  src={card.icon}
                  alt={"title for" + card.title + "verification portal"}
                  className="max-h-full max-w-full" /> */}
                <LazyImage
                  src={card.icon}
                  alt={"title for" + card.title + "verification portal"}
                  className="max-h-full max-w-full" />

              </div>

              {/* Card content */}
              <div>
                <h3 className="Features_heading">{card.title}</h3>
                <p className="tFeatures_text text-gray-600">{card.description}</p>
              </div>

              {/* Bottom border */}
              <div className="bottom_border"></div>

              {/* Horizontal line between the first and second card rows */}
              {index === 3 && (
                <div className="absolute top-0 left-0 right-0 h-0.5 bg-gradient-to-r from-transparent via-[#8BDDFF] to-transparent hidden lg:block"></div>
              )}
            </div>
          ))}
        </div>

      </div>
    </div>
  );
}
