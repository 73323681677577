import LazyImage from "../../../LazyImg/LazyImage";
import React, { useEffect, useRef, useState } from 'react';

// animate-slide-left
const LeftSlider = ({ images = [], speed = 150 }) => {
  const stripRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false); // To handle first load

  const containerStyle = {
    overflow: "hidden",
    position: "relative",
    width: "100%",
  };

  // Set animation speed dynamically using inline style
  const stripStyle = {
    display: "flex",
    animation: isMounted ? `moveLeft ${speed}s linear infinite` : "none", // Only start animation after mounting
  };

  const keyframes = `
     @keyframes moveLeft {
       0% {
         transform: translateX(0);
       }
       100% {
         transform: translateX(-100%);
       }
     }
   `;

  // On mount, set the isMounted flag to true to trigger animation
  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (stripRef.current) {
      const strip = stripRef.current;
      const totalWidth = strip.scrollWidth / 2; // Account for duplicate logos
      strip.style.width = `${totalWidth}px`;
    }
  }, [images]);

  return (
    <div style={containerStyle} className="overflow-hidden relative w-full">
      <style>{keyframes}</style>
      {/* Duplicate logos to ensure smooth infinite scrolling */}
      <div ref={stripRef} style={stripStyle}>
        {images.concat(images).map((logo, index) => (
          <div
            key={index} // Add a unique key for each duplicated logo
            className="flex-shrink-0 mr-5 w-[175px] h-[100px] rounded-[20px] border border-[#CCCCCC]
                       flex justify-center items-center hover:border-[#1B6BB4] hover:cursor-pointer hover:shadow-[0px_4px_15px_rgba(27,107,180,0.3)] shadow-md px-3"
          >
            <img
              src={logo}
              alt={`Logo ${index + 1}`}
              className="max-w-full max-h-full object-contain mix-blend-color-burn"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default LeftSlider;
