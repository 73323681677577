import React, { useState, useMemo, useCallback, useEffect } from "react";
import BlogCard from "./components/BlogCard";
import Pagination from "./components/Pagination";
import BlogHeader from "./components/BlogsHeader";
import "./components/blog.style.css";
import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";
import { blogArray } from "../../blog";
import { Outlet } from "react-router-dom";
// import CityBlogCard from "./components/CityBlogCard";

const BlogsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [filteredBlogsArray, setFilteredBlogsArray] = useState(blogArray); // State for filtered blogs
  const [searchTimeout, setSearchTimeout] = useState(null); // State for debounce timeout
  // const [cityPageBlogs, setCityPageBlogs] = useState(cityBlogArray);

  const blogsPerPage = 9; // Set the number of blogs per page

  // Calculate the total number of pages
  const totalPages = useMemo(() => {
    return Math.ceil(filteredBlogsArray.length / blogsPerPage);
  }, [filteredBlogsArray.length, blogsPerPage]);
  // console.log("line 21",cityBlogArray)

  // Get the blogs for the current page
  const getCurrentBlogs = () => {
    const start = (currentPage - 1) * blogsPerPage;
    const end = start + blogsPerPage;
    return filteredBlogsArray.slice(start, end);
  };

  // Handle input change with debounce
  const handleSearch = useCallback((e) => {
    const value = e.target.value;
    setInputValue(value);
    // Only filter after a delay when `value` changes
    setSearchTimeout(value);
  }, []);

  useEffect(() => {
    // Only run the filter if there’s a non-empty search term
    if (searchTimeout) {
      const timeout = setTimeout(() => {
        const filteredBlogs = blogArray.filter((blog) =>
          blog.title.toLowerCase().includes(searchTimeout.toLowerCase())
        );
        setFilteredBlogsArray(filteredBlogs);
      }, 300); // Adjust the delay as needed

      return () => clearTimeout(timeout); // Clear timeout on unmount
    } else {
      // If searchTimeout is empty, show all blogs or handle it as needed
      setFilteredBlogsArray(blogArray);
    }
  }, [searchTimeout, blogArray]);
  // console.log('blogs page');
  return (
    <div>
      {/* Header */}
      <BlogHeader handleSearch={handleSearch} inputValue={inputValue} />
      {/* center this div */}
      <div
        className="max-w-5xl relative top-32 mx-auto  px-4 sm:px-6 lg:px-8 "
        id="my-section"
      >
        {/* Blogs component */}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 py-8">
          {getCurrentBlogs().map((blog) => (
            <BlogCard key={blog.id} blog={blog} />
          ))}
        </div>
        {/* {currentPage === 1 && <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 py-8">
        {cityBlogArray.map((blog, idx) => (<CityBlogCard key={idx} blog={blog} />))}
        </div>} */}
        {/* Pagination component */}
        <div className="flex justify-center mb-20">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
      <div className="relative top-28  left-0 right-0">
        <HelpCard />
      </div>
      {/* <Outlet /> */}
    </div>
  );
};

export default BlogsPage;
