import FaqComponent from "../../Components/Footer/FaqComponent/FaqComponent";
import ServicesCardSection from "../ItPage/ServicesCardSection/ServicesCardSection";
import BgvServiceSection from "../ItPage/BgvServiceSection/BgvServiceSection";
import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";
import BgvStepsSection from "../ItPage/BgvStepsSection/BgvStepsSection";
import BrandCarousel from "../ItPage/BrandCarousel/BrandCarousel";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";

const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

// const about_bg = `${digitalOceanURL}/About_Us_img/About_bg.png`;
// const Gane_Img = `${digitalOceanURL}/Sector/Games.png`;
const bg_image = `${digitalOceanURL}/About_Us_img/About_bg.png`;
const Logistic_img = `${digitalOceanURL}/Sector/logistics.png`;

export default function Logistics() {

  return (
    <div>
      <div className=" relative  background_color bottom_space">
        {/* Image that is hidden on small screens */}
        <img
          src={bg_image}
          alt="logistics img hero section"
          className="absolute  object-cover w-full lg:h-[150%] h-[110%] md:h-[120%] bg_curve_img"
        />

        <div className="mb-16 relative  w-full h-full md:top-28 lg:top-0">
          <div className="grid grid-cols-12 gap-4 justify-items-center relative top-0 lg:top-32 lg:mx-20 xl:mx-32 2xl:mx-40 items-center">
            {/* Text container */}
            <section className="col-span-12 md:col-span-12 lg:col-span-7 w-full Banking_heading font-semibold font-[poppins] top_spacing">
              <span className="industry_info">
                Logistics
              </span>
              <h1 className="w-full text-[32px] md:text-[40px] lg:text-[46px]">
                Revolutionizing Logistics with AI-Driven Efficiency and Risk
                Management
              </h1>
              <h2 className="about-text-para mt-2 " aria-label="Streamline operations, reduce risks, and enhance supply chain efficiency with AI-powered solutions tailored for the logistics industry.">
                Streamline operations, reduce risks, and enhance supply chain
                efficiency with AI-powered solutions tailored for the logistics
                industry.
              </h2>

              <div className="mt-4">
                <ButtonComponent arialLabel={"Get in touch with an expert for Revolutionizing Logistics with AI-Driven Efficiency and Risk Management"} />
              </div>

            </section>

            {/* Image container */}
            <div className="col-span-12 md:col-span-12 lg:col-span-5 relative   lg:top-4 ">
              <img
                src={Logistic_img}
                alt="about-girl img logistics sector"

                className="w-full h-auto object-cover"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="lg:mt-[24rem] md:mt-[12rem] mt-[8rem]">
        <BrandCarousel />
      </div>
      {/* 3 section */}
      <BgvServiceSection Sector="Logistic Sector" />
      {/* 4 section */}
      {/*  <BgvStepsSection /> */}
      {/* 5 section */}
      <ServicesCardSection Sector="Logistic Sector" Sector_cards='manufacture' />
      <div className="mb-48">
        {<BgvStepsSection />}
      </div>
      <FaqComponent />
      <div className="relative top-28  left-0 right-0">
        <HelpCard />
      </div>
    </div>
  );
}
