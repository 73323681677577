import React, { useRef, useState } from "react";

const digitalOceanURL =
    "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const normalID = `${digitalOceanURL}/allIndAssets/Id-industry-blue.svg`;
const hoverID = `${digitalOceanURL}/allIndAssets/Id-industry-white.svg`;

const normalFinance = `${digitalOceanURL}/allIndAssets/finance-blue.svg`;
const hoverFinance = `${digitalOceanURL}/allIndAssets/finance-white.svg`;

const normalMedical = `${digitalOceanURL}/allIndAssets/health-blue.svg`;
const hoverMedical = `${digitalOceanURL}/allIndAssets/health-white.svg`;

const normalLogistics = `${digitalOceanURL}/allIndAssets/logistics-blue.svg`;
const hoverLogistics = `${digitalOceanURL}/allIndAssets/logistics-white.svg`;

const normalEcommerce = `${digitalOceanURL}/allIndAssets/ecommerce-blue.svg`;
const hoverEcommerce = `${digitalOceanURL}/allIndAssets/ecommerce-white.svg`;

const normalEducation = `${digitalOceanURL}/allIndAssets/education-blue.svg`;
const hoverEducation = `${digitalOceanURL}/allIndAssets/education-white.svg`;

const normalGame = `${digitalOceanURL}/allIndAssets/game-blue.svg`;
const hoverGame = `${digitalOceanURL}/allIndAssets/game-white.svg`;

const normalTravel = `${digitalOceanURL}/allIndAssets/travel-blue.svg`;
const hoverTravel = `${digitalOceanURL}/allIndAssets/travel-white.svg`;

const Left_img = `${digitalOceanURL}/Sector/Govt_API_bg.png`;
// ----------------- api array-----------------------------
const IndustryCards = [
    {
        id: 1,
        heading: "ID Verification",
        hover: normalID,
        normal: hoverID
    },
    {
        id: 2,
        heading: "Finance",
        hover: normalFinance,
        normal: hoverFinance
    },
    {
        id: 3,
        heading: "Medical",
        hover: normalMedical,
        normal: hoverMedical
    },
    {
        id: 4,
        heading: "Logistics",
        hover: normalLogistics,
        normal: hoverLogistics
    },
    {
        id: 5,
        heading: "E-Commerce",
        hover: normalEcommerce,
        normal: hoverEcommerce
    },
    {
        id: 6,
        heading: "Education",
        hover: normalEducation,
        normal: hoverEducation
    },
    {
        id: 7,
        heading: "Game",
        hover: normalGame,
        normal: hoverGame
    },
    {
        id: 8,
        heading: "Travel",
        hover: normalTravel,
        normal: hoverTravel
    },
];

const PickIndustryComponent = () => {
    const [showAll, setShowAll] = useState(false);
    const newCardsRef = useRef(null); // Step 1: Create a reference

    // Initially, show 8 cards; when 'showAll' is true, show all cards
    const visibleCards = showAll ? IndustryCards : IndustryCards.slice(0, 8);

    const handleShowAll = (e) => {
        setShowAll(!showAll);
        setTimeout(() => {
            if (newCardsRef.current) {
                newCardsRef.current.scrollIntoView({ behavior: "smooth" }); // Step 2: Scroll to the new cards smoothly
            }
        }, 0);
    };

    return (
        <div className=" mt-4 mb-20 mx-4">
            <div className=" pt-16 pb-16 relative ">

                <img loading="lazy" src={Left_img} className="absolute -top-[35%] -left-1/2" alt="left-side img" />

                <div className="text-center mb-24 mt-20">
                    <h2 className=" inline font-semibold mb-6  md:text-[44px] lg:text-[48px] section_headings">
                        Pick Your {" "}
                    </h2>
                    <span className=" font-semibold mb-6  md:text-[44px] text-[#1580C2] lg:text-[48px] section_headings">
                        Industry
                    </span>
                    <p className="IT_service_Para">
                        Discover how we enable 2000+ companies with comprehensive Screening & Due Diligence
                    </p>
                </div>

                <div className="grid lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-3 gap-4 relative lg:mx-20">
                    {visibleCards.map((card, index) => (
                        <div
                            key={card.id}
                            className={`govtAPICard cursor-pointer relative  group `}
                            ref={index === 8 ? newCardsRef : null} // Step 3: Add ref to the 9th card to scroll to this position
                        >
                            <div className="Sector_Section_5_parent flex flex-col justify-center  items-center h-full overflow-hidden gap-4   px-1">
                                {/* Image */}
                                <div className="govtAPICard_child bg-[#E6F5FF] group-hover:bg-[#2096d3] rounded-full flex justify-center items-center w-24 h-24 transition-colors duration-300">
                                    <img
                                        loading="lazy"
                                        src={card.hover}
                                        alt={`${card.heading} hover icon`}
                                        className="absolute transition-opacity duration-300 opacity-100 group-hover:opacity-0"
                                    />

                                    {/* Hover image */}
                                    <img loading="lazy"
                                        src={card.normal}
                                        alt={`${card.heading} normal icon`}
                                        className="absolute transition-opacity duration-300 opacity-0 group-hover:opacity-100"
                                    />
                                </div>
                                {/* Text */}
                                <div>
                                    <p className="govt_card_heading text-center mt-1">
                                        {card.heading}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {!showAll && (
                    <div className="viewAllButton md:mt-24 mt-12 flex justify-center">
                        <button
                            onClick={handleShowAll}
                            className="rounded-full view_all text-white w-1/2 md:w-[20%] lg:w-[13%]"
                        >
                            View All
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PickIndustryComponent;
