import React, { useState, useEffect } from "react";

const LazyImage = ({ src, alt, className, Title }) => {
    const [isVisible, setIsVisible] = useState(false);
    const imageRef = React.useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect(); // Stop observing once loaded
                }
            },
            { threshold: 0.1 }
        );

        if (imageRef.current) observer.observe(imageRef.current);

        return () => observer.disconnect();
    }, []);

    return (
        <img
            ref={imageRef}
            src={isVisible ? src : ""}
            alt={alt}
            title={Title}
            className={className}
        />
    );
};

export default LazyImage;
