import { useLocation } from "react-router-dom";

function useCheckForXSS() {
    const location = useLocation();

    // Define suspicious patterns that are commonly used in XSS attacks
    const suspiciousPatterns = /<script|<\/script|javascript:|onerror|onload|alert|eval|<img/i;

    // Check URL parts for suspicious content (pathname, search, hash)
    if (suspiciousPatterns.test(location.pathname) || suspiciousPatterns.test(location.search) || suspiciousPatterns.test(location.hash)) {
        return true; // Suspicious content found
    }
    return false;
}

export default useCheckForXSS;