import Aditya_Birla from '../../assets/Aditya_birla.png'
import Ultratech from '../../assets/Ultratech.png'
import Times from '../../assets/Times.png'
import STL from '../../assets/STL.png'

const digitalOceanURL =
    "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const avaniLogo = `${digitalOceanURL}/Clients_PNG/avani.jpeg`;
const client1 = `${digitalOceanURL}/Clients_PNG/Appzest.png`;
const client2 = `${digitalOceanURL}/Clients_PNG/ChitMonks-logo.png`;
const client3 = `${digitalOceanURL}/Clients_PNG/bluesemi-logo.png`;
// const client4 = `${digitalOceanURL}/Clients_PNG/camfil-logo.png`;
const client5 = `${digitalOceanURL}/Clients_PNG/crome-check-logo.png`;
const client6 = `${digitalOceanURL}/Clients_PNG/elogix-logo.png`;
// const client7 = `${digitalOceanURL}/Clients_PNG/first-view-logo.png`;
const client8 = `${digitalOceanURL}/Clients_PNG/t-hub-logo.png`;
const client9 = `${digitalOceanURL}/Clients_PNG/monitra-logo.png`;
const client10 = `${digitalOceanURL}/Clients_PNG/AriqT-logo.png`;
const client11 = `${digitalOceanURL}/Clients_PNG/JNTU.png`;
const client12 = `${digitalOceanURL}/Clients_PNG/appstek_corp_logo.png`;
const client13 = `${digitalOceanURL}/Clients_PNG/icici-bank-logo.png`;
const client14 = `${digitalOceanURL}/Clients_PNG/image 48.png`;
const client15 = `${digitalOceanURL}/Clients_PNG/image 49.png`;
const client16 = `${digitalOceanURL}/Clients_PNG/image 50.png`;
const client17 = `${digitalOceanURL}/Clients_PNG/kanerika.png`;
const client18 = `${digitalOceanURL}/Clients_PNG/startupindia.png`;
const client19 = `${digitalOceanURL}/Clients_PNG/startups.png`;
const dealLogo = `${digitalOceanURL}/deal_logo.svg`;
const client20 = `${digitalOceanURL}/NewClients/defa.svg`;
const client21 = `${digitalOceanURL}/NewClients/hpl.svg`;
const client22 = `${digitalOceanURL}/NewClients/91springboard.svg`;
const client23 = `${digitalOceanURL}/NewClients/blix.svg`;
const client24 = `${digitalOceanURL}/NewClients/goken.svg`;
const client25 = `${digitalOceanURL}/NewClients/jewelbox.svg`;
const client26 = `${digitalOceanURL}/NewClients/nasscom.svg`;
const client27 = `${digitalOceanURL}/NewClients/phenom.svg`;
const client28 = `${digitalOceanURL}/NewClients/sps.svg`;
const client29 = `${digitalOceanURL}/NewClients/tfg.svg`;
const client30 = `${digitalOceanURL}/NewClients/trade-fantasy.svg`;
const client31 = `${digitalOceanURL}/NewClients/volta.svg`;

const leftArray = [
    client2,
    client3,
    client5,
    client6,
    STL,
    Ultratech,
    client8,
    client9,
    avaniLogo,
    client10,
    client1,
    client15,
    client11,
    client13,
    client16,
    client17,
    client18,
    client14,
    client27,
    client19,
    dealLogo,
    client29,
    client24,
    client30,
    client26,
    client20,
    client21,
    client22,
    client31,
    client12,
    client23,
    client28,
    client25,
  
];


const rightArray = [
    client2,
    client3,
    client5,
    client17,
    client30,
    client10,
    client1,
    client15,
    dealLogo,
    client21,
    client22,
    client31,
    client6,
    client8,
    client9,
    avaniLogo,
    client16,
    client12,
    client23,
    client28,
    client25,
    client27,
    client19,
    client29,
    client24,
    client18,
    client14,
    client26,
    client20,
    client11,
    client13,
    Aditya_Birla,
    Times
];

export { leftArray, rightArray };