import React from "react";
import BrandCarousel from "../ItPage/BrandCarousel/BrandCarousel";
import BgvServiceSection from "../ItPage/BgvServiceSection/BgvServiceSection";
import BgvStepsSection from "../ItPage/BgvStepsSection/BgvStepsSection";
import ServicesCardSection from "../ItPage/ServicesCardSection/ServicesCardSection";
import FaqComponent from "../../Components/Footer/FaqComponent/FaqComponent";
import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";

const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const bg_image = `${digitalOceanURL}/About_Us_img/About_bg.png`;
const Medical_Image = `${digitalOceanURL}/Sector/Medical_img.png`;

export default function MedicalSector() {
  return (
    <div>
      <div className=" relative  background_color bottom_space">
        {/* Image that is hidden on small screens */}
        <img
          src={bg_image}
          alt="medical sector hero img"
          className="absolute  object-cover w-full lg:h-[150%] h-[110%] md:h-[120%] bg_curve_img"
        />

        <div className="mb-16 relative  w-full h-full md:top-28 lg:top-0">
          <div className="grid grid-cols-12 gap-4 justify-items-center relative top-0 lg:top-32 lg:mx-20 xl:mx-32 2xl:mx-40 items-center">

            {/* Text container */}
            <div className="col-span-12 md:col-span-12 lg:col-span-7 w-full Banking_heading font-semibold font-[poppins] top_spacing">
              <span className="industry_info">Healthcare and Pharma</span>
              <h1 className="w-full text-[32px] md:text-[40px] lg:text-[46px]">
                Streamline Healthcare Background Verification with Hirestar</h1>
              <h2 className="about-text-para mt-2 ">
                Hirestar revolutionizes the onboarding process for the healthcare and pharmaceutical industries by offering fast, secure, and reliable digital background verification. Say goodbye to time-consuming manual checks and ensure that your stakeholders are verified quickly and accurately, allowing you to focus on delivering quality care.
              </h2>
              {/* Button Group */}
              <div className="mt-4">
                <ButtonComponent arialLabel={"Get in touch with an expert for Streamline Healthcare Background Verification with Hirestar"} />
              </div>
            </div>


            {/* Image container */}
            <div className="col-span-12 md:col-span-12 lg:col-span-5 relative   lg:top-8 ">
              <img src={Medical_Image} alt="about-girl img medical sector" className="w-full h-auto object-cover" />
            </div>
          </div>
        </div>
      </div>

      <div className="lg:mt-[24rem] md:mt-[12rem] mt-[8rem]">
        <BrandCarousel />
      </div>
      <BgvServiceSection Sector="Medical Sector" />
      {/* <BgvStepsSection /> */}
      <ServicesCardSection Sector="Medical Sector" Sector_cards='health' />
      <div className="mb-48">
        {<BgvStepsSection />}
      </div>
      <FaqComponent />
      <div className="relative top-28  left-0 right-0">
        <HelpCard />
      </div>
    </div>

  );
}
