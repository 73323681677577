import { useEffect } from "react";
import LazyImage from "../../LazyImg/LazyImage";
import "./not-found.style.css";
import { useNavigate } from "react-router-dom";
const digitalOceanURL =
    "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const notFoundIcon = `${digitalOceanURL}/404Asset/not_found.gif`;
const gearIcon = `${digitalOceanURL}/404Asset/not-found-gear.png`;

const NotFound = () => {

    useEffect(() => {
        document.title = 'Page Not Found - Let\'s Help You Get Back!'; // Optional: Set page title
        // You can also set a custom HTTP status code on the client side.
        window.history.replaceState(null, '', '/page-not-found'); // Simulate URL change
    }, []);
    const navigate = useNavigate();
    return (
        <div className="flex flex-col  items-center justify-center min-h-screen bg-gray-100 not_found_container relative overflow-hidden">
            {/* Top shape */}
            {/* <div className="absolute top-0 left-0 w-full h-[150px] md:h-[200px]">
                <img src={shape_top} alt="shape" className="w-full h-full object-cover" />
            </div> */}

            {/* Middle Wrapper */}
            <div className="flex flex-col items-center justify-center text-center space-y-6 z-10 ">
                {/* Not Found Icon Container */}
                <div className="flex items-center justify-center gap-3  relative top-24">
                    <div className="gear-container">
                        {/* <img src={gearIcon} alt="left_gear_img for 404 page" title="left_gear_img for 404 page " className="gearIcon" /> */}
                        <LazyImage src={gearIcon} alt="left_gear_img for 404 page" Title="left_gear_img for 404 page " className="gearIcon" />
                    </div>
                    <div className="not-found-div flex items-center justify-center">
                        {/* <img src={notFoundIcon} title="Illustration for not found page" alt="Illustration for 404 page" className="not-found-img " /> */}
                        <LazyImage src={notFoundIcon} Title="Illustration for not found page" alt="Illustration for 404 page" className="not-found-img " />
                    </div>
                    <div className="gear-container">
                        {/* <img src={gearIcon} alt="right_gear_img for 404 page" title="right_gear_img for 404 page" className="gearIcon" /> */}
                        <LazyImage src={gearIcon} alt="right_gear_img for 404 page" Title="right_gear_img for 404 page" className="gearIcon" />
                    </div>
                </div>

                {/* Not Found Text */}
                <div className="space-y-4">
                    <h1 className="text-3xl md:text-5xl font-semibold not-found-title">Page Not Found</h1>
                    <p className="text-lg md:text-xl text-gray-600 not-found-heading">
                        Sorry, we couldn't find the page you are looking for
                    </p>
                    <div className="rounded-full lg:px-2 py-2 help_center text-white  lg:w-[40%] md:w-[50%] w-[60%] mx-auto mb-8">
                        <button
                            onClick={() => navigate('/')}
                            className="About_btn "
                        >
                            Go to Home Page
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default NotFound