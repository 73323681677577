import TestimonialComponent from "../../../Components/Testimonial/TestimonialComponent";
import LazyLoad from "react-lazy-load";
const TestimonialSection = () => {
  return (
    <>
      <LazyLoad offset={0} once={true}>
        <TestimonialComponent />
      </LazyLoad>
    </>
  );
};

export default TestimonialSection;
