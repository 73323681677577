
import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";
import ServicesCardSection from "../ItPage/ServicesCardSection/ServicesCardSection";
import FaqComponent from "../../Components/Footer/FaqComponent/FaqComponent";
import BgvStepsSection from "../ItPage/BgvStepsSection/BgvStepsSection";
import BgvServiceSection from "../ItPage/BgvServiceSection/BgvServiceSection";
import BrandCarousel from "../ItPage/BrandCarousel/BrandCarousel";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";

const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const bg_image = `${digitalOceanURL}/About_Us_img/About_bg.png`;
const Banking_img = `${digitalOceanURL}/Sector/Banking.png`;

export default function Banking() {

  return (
    <div>
      <div className="relative  background_color  ">
        {/* Image that is hidden on small screens */}
        <img
          src={bg_image}
          alt="hero img for banking sector"
          className="absolute  object-cover w-full lg:h-[150%] h-[110%] md:h-[120%] bg_curve_img"
        />

        <div className="mb-16 relative  w-full h-full md:top-28 lg:top-0 ">
          <div className="grid grid-cols-12 gap-4 justify-items-center relative top-0 lg:top-32 lg:mx-20 xl:mx-32 2xl:mx-40 items-center ">
            {/* Text container */}
            <div className="col-span-12 md:col-span-12 lg:col-span-7  Banking_heading font-semibold font-[poppins]  top_spacing mx-2 lg:mx-0">
              <span className="industry_info">
                Banking and Financial Services
              </span>
              <h1 className="w-full text-[32px] md:text-[40px] lg:text-[46px]">
                Your Reliable All in One AI-Driven Fraud Prevention and Risk
                Management
              </h1>
              {/* h2 heading */}
              <h2 className="about-text-para mt-2 ">
                Enhance your operations with solutions that comply with
                regulations and foster trust throughout your digital onboarding
                and verification processes.
              </h2>

              {/* Button Group */}
              <div className="mt-4">
                <ButtonComponent arialLabel={"Get in touch with an expert for automated background checks in Banking sector"} />
              </div>
            </div>

            {/* Image container */}
            <div className="col-span-12 md:col-span-12 lg:col-span-5 relative   lg:-top-8 bottom_space lg:mb-0 mb-20">
              <img
                src={Banking_img}
                alt="about-girl banking sector"
                className="w-full h-auto object-cover"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="lg:mt-[24rem] md:mt-[12rem] mt-[8rem]">
        <BrandCarousel />
      </div>
      {/* 3 section */}
      <BgvServiceSection Sector="Banking Sector" />
      {/* 4 section */}
      {/* 5 section */}
      <ServicesCardSection Sector_cards='banking' Sector='Banking Sector' />

      <div className="mb-48">
        {<BgvStepsSection />}
      </div>

      <FaqComponent />
      <div className="relative top-28  left-0 right-0">
        <HelpCard />
      </div>
    </div>
  );
}
