import Carousel from "./BlogsCarousel";
// import Location from "./components/locationComponent/Location";
import ParaGraphSection from "./components/ParagraphSection/ParaGraphSection";
import SingleBlogHeroSection from "./components/SingleBlogHeroSection/SingleBlogHeroSection";
import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";
import { useParams } from "react-router-dom";
import { blogArray } from "../../blog";
import "./singleBlogs.style.css";
import PageForCities from "../PagesForCities/PageForCities";
import slugify from 'slugify';

const SingleBlogPage = () => {
  const { slug } = useParams(); // Get the slug from the URL

  // Simulate fetching the blog post by its slug
  const blog = blogArray?.find(
    (b) => slugify(b.title, { lower: true, strict: true }) === slug
  );

  if (!blog) {
    return <p className="text-center">Blog not found</p>;
  }


  return (
    <div>
      {/* <div className="section">
        <SingleBlogHeroSection />
        <Location />
      </div> */}
      {blog.blog_type === "city" ? (
        <PageForCities blog={blog} />
      ) : (
        <>
          <div className="mt-20">
            <SingleBlogHeroSection singleBlog={blog} />
            {/* <Location singleBlog={blog} /> */}
            <ParaGraphSection singleBlog={blog} />
            <Carousel blogArray={blogArray} />
          </div>
          <div className="relative top-28  left-0 right-0">
            <HelpCard />
          </div>
        </>
      )}
    </div>
  );
};

export default SingleBlogPage;
