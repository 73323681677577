import React from "react";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";
import ServicesCardSection from "../ItPage/ServicesCardSection/ServicesCardSection";
import BrandCarousel from "../ItPage/BrandCarousel/BrandCarousel";
import BgvServiceSection from "../ItPage/BgvServiceSection/BgvServiceSection";
import BgvStepsSection from "../ItPage/BgvStepsSection/BgvStepsSection";
import FaqComponent from "../../Components/Footer/FaqComponent/FaqComponent";
const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";


const bg_image = `${digitalOceanURL}/About_Us_img/About_bg.png`;
const manufacture = `${digitalOceanURL}/Sector/Manufacture.png`;

export default function Manufacture() {
  return (
    <div>
      <div className="relative background_color  bottom_space ">
        {/* Image that is hidden on small screens */}
        <img
          src={bg_image}
          alt="manufacture hero sector img"
          className="absolute  object-cover w-full lg:h-[150%] h-[110%] md:h-[120%] bg_curve_img"
        />

        <div className="mb-16 relative  w-full h-full md:top-28 lg:top-0 ">
          <div className="grid grid-cols-12 gap-4 justify-items-center relative top-0 lg:top-32 lg:mx-20 xl:mx-32 2xl:mx-40 items-center">
            {/* Text container */}
            <div className="col-span-12 md:col-span-12 lg:col-span-7 w-full Banking_heading font-semibold font-[poppins] top_spacing">
              <span className="industry_info">
                Manufacturing
              </span>
              <h1 className="w-full text-[32px] md:text-[40px] lg:text-[46px]">
                Ensure Accuracy Before Manufacturing
              </h1>
              <h2 className="about-text-para mt-2 ">
                We provide customised risk management solutions, tailored to
                meet the unique needs of leaders and innovators in the
                manufacturing industry.
              </h2>

              {/* Button Group */}
              <div className="mt-4">
                <ButtonComponent arialLabel="Get in touch with an expert for Ensure Accuracy Before Manufacturing" />
              </div>
            </div>

            {/* Image container */}
            <div className="col-span-12 md:col-span-12 lg:col-span-5 relative   lg:mt-0 ">
              <img
                src={manufacture}
                alt="about-girl for manufacture sector"
                className="w-full h-auto object-cover"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="lg:mt-[24rem] md:mt-[12rem] mt-[8rem]">
        <BrandCarousel />
      </div>
      {/* 3 section */}
      <BgvServiceSection Sector="Manufacture Sector" />
      {/* 4 section */}
      {/*   <BgvStepsSection /> */}
      {/* 5 section */}
      <ServicesCardSection Sector="Manufacture Sector" Sector_cards='manufacture' />
      <div className="mb-48">
        {<BgvStepsSection />}
      </div>
      <FaqComponent />
      <div className="relative top-28  left-0 right-0">
        <HelpCard />
      </div>
    </div>
  );
}
