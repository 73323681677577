import ContactHireStar from "../../../Components/ContactHire/ContactHirestar";
import LazyLoad from "react-lazy-load";
const WhyHirestarSection = () => {
  return (
    <div>
      <LazyLoad offset={0} once={true}>
        <ContactHireStar />
      </LazyLoad>
    </div>
  );
};

export default WhyHirestarSection;
