import React from "react";
import { useNavigate } from "react-router-dom";
import LazyImage from "../../../LazyImg/LazyImage";
const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const About_Image = `${digitalOceanURL}/About_Us_img/About_info.png`;
const service_bg = `${digitalOceanURL}/serviec-image/service.png`;
const top_img = `${digitalOceanURL}/About_Us_img/about_top_img.png`;
const bottom_img = `${digitalOceanURL}/About_Us_img/about_bottom_img.png`;

const AboutUsInfoSection = () => {

  const navigate = useNavigate();

  const handleReadMoreClick = () => {
    navigate('/Self_Verification');
  };

  return (
    <div className="mx-2 lg:mx-0 ">
      {/* <img loading="lazy" src={service_bg} alt="" className=' absolute ' /> */}

      {/* Top Image */}
      <img loading="lazy" src={top_img} alt="top_img about us page" title="top_img for about us page" className="absolute  h-1/2 w-96 bg_curve_img" />

      <div className="grid lg:grid-cols-2 md:grid-cols-1 lg:mx-12 sm:grid-cols-1 sm:gap-8 About-box lg:top:44 md:top-40 top-4 relative justify-center text-center">

        <div className="flex justify-center">
          {/* Girl Image */}
          <img loading="lazy" src={About_Image} alt="about us info girl " title="About_image for info girl" className="lg:mx-0 md:mx-auto" />
        </div>

        <div className="about-text-box  about_info flex flex-col justify-center items-center lg:items-start lg:text-left md:text-center">
          <div>
            <button className="rounded-full text-black p-1 h-[37px] w-32 mb-2 mt-2 About-btn font-[16px]">
              OUR VISION
            </button>
          </div>
          <div className="mt-2" aria-label="HireStar.io: Streamlining Background Verifications with Blockchain">

            {/* <h2 className="About-Heading text-[32px] md:text-[40px] lg:text-[42px]" aria-label="HireStar.io: Streamlining Background Verifications with Blockchain">
              HireStar.io: <br /> Streamlining Background Verifications with Blockchain
            </h2> */}
            {/* ---------------updated heading---------------------- */}
            <h2 className="About-Heading text-[32px] md:text-[40px] lg:text-[42px]" aria-label="HireStar.io: Streamlining">
              HireStar.io: Streamlining
            </h2>
            <h2 className="About-Heading text-[32px] md:text-[40px] lg:text-[42px]" aria-label=" Background Verifications with Blockchain">
              Background Verifications with Blockchain
            </h2>
            {/* ------------------end------------------ */}
          </div>
          <div className="mt-4">
            <p className="font-[poppins] font-[16px]">
              At HireStar.io, we're transforming background verifications using blockchain technology. Our platform ensures seamless, tamper-proof checks for educational degrees, past employment, Aadhaar, PAN, driving licenses, face verification, crime checks, and drug tests. Designed for HR professionals, we simplify the hiring journey with fast, transparent, and secure processes. Additionally, our offer letter generation feature helps tackle moonlighting and offer shopping, ensuring a smooth and reliable hiring process. Trust HireStar.io for comprehensive verification services that help you hire with confidence.</p>
          </div>
          {/*   <div className="mt-4">
            <button className="rounded-full text-white w-[134px] h-[52px] mb-2 mt-2 Read-More-btn font-[16px]" onClick={handleReadMoreClick}>
              Read More{" "}
            </button>
          </div> */}
        </div>

        {/* Bottom Image */}
        {/* <img
          src={bottom_img}
          alt="bottom img about us page"
          title="bottom_img for info section"
          className="absolute lg:-right-12 right-0 top-8 bg_curve_img "
        /> */}
        <LazyImage
          src={bottom_img}
          alt="bottom img about us page"
          Title="bottom_img for info section"
          className="absolute lg:-right-12 right-0 top-8 bg_curve_img "
        />
      </div>
      {/* Bottom Image */}
    </div>

  );
};

export default AboutUsInfoSection;
