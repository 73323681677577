
import HR_Portal_Section from "./Hr_Portal_Section";
import PortalSection3 from "./Portal_Section_3";
import TestimonialSection from "../HomePage/TestimonialSection/TestimonialSection";
import FaqComponent from "../../Components/Footer/FaqComponent/FaqComponent";
import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";
import BgvStepsSection from "../ItPage/BgvStepsSection/BgvStepsSection";
// import { useNavigate } from "react-router-dom";
import BrandCarousel from "../ItPage/BrandCarousel/BrandCarousel";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";

const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const Hr_Image = `${digitalOceanURL}/Portal_Image/Hr_Portal_Image.png`;
const Hr_Small_Image = `${digitalOceanURL}/Portal_Image/Hr_Portal_Small_Image.png`;

export default function HR_Portal() {

  return (
    <div>
      <div className="relative blog_bg_image mb-24 lg:mb-0 background_color">
        {/* ---------------------------show this for lg and md device------------------------------------- */}
        <div className="lg:relative lg:top-12 flex flex-col lg:mx-12 top_image_gap1">
          <div className="flex flex-col lg:relative lg:right-10 leading-tight z-[2] portal-box">
            <div className="p-2 mt-4 lg:ml-16">
              <div aria-label="Introducing the Hirestar.io HR Portal" className="text-wrap  text-[32px] md:text-[46px] lg:text-[46px] mt-14 lg:mt-0 md:mt-20 font-semibold font-[poppins] text-center">
                {/* <h1 className="text-[32px] md:text-[46px] lg:text-[46px]" aria-label="Introducing the Hirestar.io HR Portal">
                  Introducing the Hirestar.io
                  <span className="text-[#1783C4]"> HR Portal</span>
                </h1> */}
                <h1 className="inline" >
                  Introducing The Hirestar.io{" "}
                </h1>
                <span className="text-[#1783C4]">
                  HR Portal
                </span>
              </div>
              <div className="text-wrap px-2 lg:px-0 md:px-0 font-[poppins] font-[16px] mt-4 md:mt-6 lg:mt-6 text-center paragraph">
                <p>
                  Streamline hiring with secure, blockchain-based background checks
                  <br className="hidden md:block lg:block" />
                  Trust Hirestar.io for verified and transparent employee screening
                </p>
              </div>
            </div>

            {/* --------------------------buttons-------------------- */}
            <ButtonComponent arialLabel={"Get in touch with an expert to know more about our HR Portal"} />
          </div>

          <div className="large-image">
            {/* Home Image */}
            <img
              className="home-image sm:mb-12 lg:relative w-full "
              alt="Home img for hr portal"
              src={Hr_Image}
              title="hr portal large screen image verification"
              loading="lazy"
            />
          </div>

          {/* -------------------------show this for small device-------------------------------------- */}
          <div className="relative w-full max-w-sm mx-auto small-image">
            <div className="overflow-hidden rounded-lg">
              <div className="flex transition-transform duration-300 ease-in-out mb-20">
                <img
                  src={Hr_Small_Image}
                  alt="small_screen_image for hr portal"
                  title="small screen image verification"
                  className="w-full h-auto flex-shrink-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-20 lg:mb-0  mb-16">
        <BrandCarousel />
      </div>
      <div className="section_spacing">
        <HR_Portal_Section />
      </div>
      <PortalSection3
        heading="HR Portal"
        Paragraph="Tackle hiring challenges head-on. The portal addresses common issues like moonlighting, offer shopping, and late joining by streamlining the offer process. Stay ahead of potential pitfalls and ensure a smooth transition from offer to onboarding"
        section="HR_Portal"
      />
      <BgvStepsSection />
      <div >
        <TestimonialSection />
      </div>
      <div >
        <FaqComponent />
      </div>
      <div className="relative top-28  left-0 right-0">
        <HelpCard />
      </div>
    </div>
  );
}
